import React, { useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import useGetHouseListApi from '../../../hooks/system-configuration/useGetHouseListApi';
import { SpinningBlueCircleLoader } from '../../../components/common/loaders';

import defaultUser from "../../../assets/images/user/default_user-gray.png";
import { toast } from 'react-toastify';
import { useGetApiClient } from '../../../api/useApiClient';
import useGetRolesApi from '../../../hooks/role/useGetRolesApi';

const CreateUpdateHomeownerModal = ({show, setShow }) => {
    const {loading, houseList} = useGetHouseListApi(0, 100);
    const {loading: rolesMenuLoading, rolesMenus} = useGetRolesApi();

    // console.log(rolesMenus)

    const [isStateUpdated, setIsStateUpdated] = useState(false);
    const [mainHomeowner, setMainHomeowner] = useState(null);
    const [newHousehold, setNewHousehold] = useState({
        name: '',
        phone: ''
    });
    const [houseHolds, setHouseholds] = useState([]);
    const [newCar, setNewCar] = useState({
        make: '',
        model: '',
        year: '',
        color: '',
        plateNumber: '',
    });
    const [cars, setCars] = useState([])

    const handleAddArrayFn = (type) => {
        switch (type) {
            case 'households':
                if (Object.values(newHousehold).every(value => value !== null && value !== undefined && value !== "")) {
                    setHouseholds([
                        ...houseHolds,
                        {
                            name: newHousehold?.name,
                            phone: newHousehold?.phone
                        }
                    ]);
                    setNewHousehold({
                        name: '',
                        phone: ''
                    });
                } else {
                    toast.error("Please fill out all of the fields");
                }
                break;
            case 'cars':
                if (Object.values(newCar).every(value => value !== null && value !== undefined && value !== "")) {
                    setCars([
                        ...cars,
                        {
                            make: newCar?.make,
                            model: newCar?.model,
                            year: newCar?.year,
                            color: newCar?.color,
                            plateNumber: newCar?.plateNumber,
                        }
                    ]);
                    setNewCar({
                        make: '',
                        model: '',
                        year: '',
                        color: '',
                        plateNumber: '',
                    });
                } else {
                    toast.error("Please fill out all of the fields");
                }
                break;
            default:
                toast.error("Invalid action");
        }
    };

    const handleRemoveArrayFn = ({index, type}) => {
        if (type === 'households') {
            let newHouseholdsArray = houseHolds.filter((_, idx) => idx !== index);
            setIsStateUpdated(true);
            setHouseholds(newHouseholdsArray);
        } else {
            let newCarsArray = cars.filter((_, idx) => idx !== index);
            setIsStateUpdated(true);
            setCars(newCarsArray);
        }
    };

    useEffect(() => {
        if (isStateUpdated) setIsStateUpdated(false);
    }, [isStateUpdated])



    const inputValuesArrayValidationFn = (input) => {
        let inputValuesArray = input?.includes(',')
        ? input?.split(',').map((item) => item.trim())
        : [input];
        return inputValuesArray;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const { data: saveHomeowner } = await useGetApiClient.post('/admin/homeowner/save',
                {
                    "houseId": e.target.houseId.value, // required | integer, id from the house list (LIST API)
                    "mainHomeowner": mainHomeowner, // required | string, value can be either OWNER or CO-OWNER
                    "accountNumber": e.target.accountNumber.value, // required | string
                    "ownerTitle": e.target.ownerTitle.value, // required | string
                    "ownerFirstName": e.target.ownerFirstName.value,  // required | string
                    "ownerLastName": e.target.ownerLastName.value,  // required | string
                    "ownerEmails": inputValuesArrayValidationFn(e.target.ownerEmails.value),
                    "ownerPhones": inputValuesArrayValidationFn(e.target.ownerPhones.value),
                    "ownerBirthDate": e.target.ownerBirthDate.value,  // required | date (YYYY-MM-DD)
                    "ownerPassword": e.target.ownerPassword.value,  // required | string
                    "ownerPicture": null,  // nullable | string, directoryPath from the upload picture (UPLOAD PICTURE API)
                    "coOwnerTitle": e.target.coOwnerTitle.value,  // conditionally required | string, required if the main homeowner is equal to CO-OWNER
                    "coOwnerFirstName": e.target.coOwnerFirstName.value, // conditionally required | string, required if the main homeowner is equal to CO-OWNER
                    "coOwnerLastName": e.target.coOwnerLastName.value, // conditionally required | string, required if the main homeowner is equal to CO-OWNER
                    "coOwnerEmails": inputValuesArrayValidationFn(e.target.coOwnerEmails.value),
                    "coOwnerPhones":inputValuesArrayValidationFn(e.target.coOwnerPhones.value),
                    "coOwnerBirthDate": e.target.coOwnerBirthDate.value, // conditionally required | date (YYYY-MM-DD), required if the main homeowner is equal to CO-OWNER
                    "coOwnerPassword": e.target.coOwnerPassword.value, // conditionally required | string, required if the main homeowner is equal to CO-OWNER
                    "coOwnerPicture": null, // nullable | string, directoryPath from the upload picture (UPLOAD PICTURE API)
                    "listNotif": { // required | object
                        "telephone": e.target.telephone.checked, // required | boolean
                        "profile": e.target.profile.checked, // required | boolean
                        "twoFa": e.target.twoFa.checked, // required | boolean
                        "sms": e.target.sms.checked, // required | boolean
                        "email": e.target.email.checked // required | boolean
                    },
                    "billingSetup": {// required | object
                        "isRental": e.target.isRental.checked, // required | boolean
                        "generateEvery": e.target.generateEvery.value, // required | integer
                        "showInvoiceDate": e.target.generateEvery.value, // required | integer
                        "monthlyFee": e.target.monthlyFee.value, // required | decimal
                        "emailRecipient": { // required | object
                            "primary": e.target.primaryEmail.value, // required | string | valid email
                            "cc": e.target.emailCC.value // nullable | string | valid email
                        },
                        "paymentMethod": e.target.paymentMethod.value // required | string
                    },
                    "households": houseHolds,
                    "cars": cars,
                    "roles": [ // optional | array | default: []
                        1
                    ]
                }
            );
            if (saveHomeowner?.error) throw new Error(saveHomeowner?.error);
            toast.success(saveHomeowner?.message);
            setShow(false);
        } catch (error) {
            toast.error(error?.message);
            console.log(error?.message);
            setShow(false);
        }
    };

    const renderModalForm = () => {
        if (loading) return <SpinningBlueCircleLoader />
        return (
            <Form className='form-horizontal' onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title className="h5 text-primary">Homeowner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Accordion id="createUpdateHomeownerModal" defaultActiveKey="0">
                        <section id="house-information">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="houseInfoHeading">
                                    House Information
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className='d-flex flex-row gap-4 align-items-center'>
                                        <label>House</label>
                                        <select
                                            className="form-select"
                                            id="houseId"
                                            name='houseId'
                                            required
                                        >
                                            <option defaultValue="-">-</option>
                                            {houseList?.houses?.map((house, idx) => (
                                                <option key={idx} value={house?.id}>{house?.houseName}</option>
                                            ))}
                                        </select>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section id="main-owner">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="ownerRenderProfHeading">
                                    Owner / Renter Profile
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col lg={9}>
                                            <Row className="form-group">
                                                <label className="control-label col-sm-2 align-self-center mb-0" htmlFor="accountNumber">Account Number:</label>
                                                <div className="col-sm-5">
                                                    <input type="text" className="form-control" id="accountNumber" name="accountNumber" placeholder="-" />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Title</label>
                                                <div className="col-sm-5">
                                                    <select
                                                        className="form-select"
                                                        id="ownerTitle"
                                                        name='ownerTitle'
                                                        required
                                                    >
                                                        <option defaultValue="-">-</option>
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Ms.">Ms.</option>
                                                    </select>
                                                </div>
                                                <div className='col-sm-5 d-flex flex-row align-items-center justify-content-end'>
                                                    <input 
                                                        className="form-check-input border border-primary me-2 m-0" 
                                                        type="checkbox" 
                                                        name="isMainOwner" 
                                                        id="isMainOwner" 
                                                        checked={mainHomeowner === "OWNER"}
                                                        onChange={() => setMainHomeowner("OWNER")}
                                                    />
                                                    <label className="form-check-label mb-0" htmlFor="isMainOwner">
                                                        Set as main homeowner
                                                    </label>  
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Full name</label>
                                                <div className="col-sm-5">
                                                    <input type="text" className="form-control" id="ownerFirstName" name="ownerFirstName" placeholder="First name.." required />
                                                </div>
                                                <div className='col-sm-5 flex flex-row gap-2 align-items-center'>
                                                    <input type="text" className="form-control" id="ownerLastName" name="ownerLastName" placeholder="Last name.." required />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Email</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="ownerEmails" name="ownerEmails" placeholder="-" required />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Phone</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="ownerPhones" name="ownerPhones" placeholder="-" required />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Birthday</label>
                                                <div className="col-sm-5">
                                                    <input type="date" className="form-control" id="ownerBirthDate" name="ownerBirthDate" placeholder="-"  required/>
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Password</label>
                                                <div className="col-sm-10">
                                                    <input type="password" className="form-control" id="ownerPassword" name="ownerPassword" placeholder="-" required />
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col lg={3} className="my-auto">
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <div>
                                                    <img
                                                        src={defaultUser}
                                                        className="img-fluid"
                                                        alt="user"
                                                        loading="lazy"
                                                    />
                                                    {/* {!loading ? (
                                                        <img
                                                            src={houseDetails?.picture ? houseDetails?.picture : (!attachedPicture ?  : attachedPicture)}
                                                            className="img-fluid"
                                                            alt="user"
                                                            loading="lazy"
                                                        />
                                                    ) : <SpinningBlueCircleLoader />} */}
                                                </div>
                                                <div className="d-flex flex-row gap-3 mt-4">
                                                    <div className="d-flex align-items-center">
                                                        <label htmlFor="ownerPicture" className="d-flex align-items-center" style={{cursor: 'pointer'}}>
                                                            <i className="material-symbols-outlined">attach_file</i>
                                                        </label>
                                                        <input
                                                            id="ownerPicture"
                                                            name="ownerPicture"
                                                            type="file"
                                                            accept="image/*"
                                                            className="d-none"
                                                            // onChange={(e) => handleAttachPicture(e)}
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="border-0 bg-transparent d-flex align-items-center text-danger"
                                                        // onClick={() => setAttachedPicture(null)}
                                                    >
                                                        <i className="material-symbols-outlined">delete</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section id="co-owner">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="coOwnerHeading">
                                    Co-owner
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col lg={9}>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Title</label>
                                                <div className="col-sm-5">
                                                    <select
                                                        className="form-select"
                                                        id="coOwnerTitle"
                                                        name='coOwnerTitle'
                                                        required={mainHomeowner === "CO-OWNER"}
                                                    >
                                                        <option defaultValue="-">-</option>
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Ms.">Ms.</option>
                                                    </select>
                                                </div>
                                                <div className='col-sm-5 d-flex flex-row align-items-center justify-content-end'>
                                                    <input 
                                                        className="form-check-input border border-primary me-2 m-0" 
                                                        type="checkbox" 
                                                        name="isCoOwner" 
                                                        id="isCoOwner" 
                                                        checked={mainHomeowner === "CO-OWNER"}
                                                        onChange={() => setMainHomeowner("CO-OWNER")}
                                                    />
                                                    <label className="form-check-label mb-0" htmlFor="isCoOwner">
                                                        Set as main homeowner
                                                    </label>  
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Full name</label>
                                                <div className="col-sm-5">
                                                    <input type="text" className="form-control" id="coOwnerFirstName" name="coOwnerFirstName" placeholder="First name.." required={mainHomeowner === "CO-OWNER"} />
                                                </div>
                                                <div className='col-sm-5 flex flex-row gap-2 align-items-center'>
                                                    <input type="text" className="form-control" id="coOwnerLastName" name="coOwnerLastName" placeholder="Last name.." required={mainHomeowner === "CO-OWNER"} />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Email</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="coOwnerEmails" name="coOwnerEmails" placeholder="-" required={mainHomeowner === "CO-OWNER"} />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Phone</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="coOwnerPhones" name="coOwnerPhones" placeholder="-" required={mainHomeowner === "CO-OWNER"} />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Birthday</label>
                                                <div className="col-sm-5">
                                                    <input type="date" className="form-control" id="coOwnerBirthDate" name="coOwnerBirthDate" placeholder="-" required={mainHomeowner === "CO-OWNER"} />
                                                </div>
                                            </Row>
                                            <Row className="form-group align-items-center">
                                                <label  className="control-label col-sm-2 align-self-center mb-0">Password</label>
                                                <div className="col-sm-10">
                                                    <input type="password" className="form-control" id="coOwnerPassword" name="coOwnerPassword" placeholder="-" required={mainHomeowner === "CO-OWNER"} />
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col lg={3} className="my-auto">
                                            <div className="d-flex flex-column align-items-center justify-content-center">
                                                <div>
                                                    <img
                                                        src={defaultUser}
                                                        className="img-fluid"
                                                        alt="user"
                                                        loading="lazy"
                                                    />
                                                    {/* {!loading ? (
                                                        <img
                                                            src={houseDetails?.picture ? houseDetails?.picture : (!attachedPicture ?  : attachedPicture)}
                                                            className="img-fluid"
                                                            alt="user"
                                                            loading="lazy"
                                                        />
                                                    ) : <SpinningBlueCircleLoader />} */}
                                                </div>
                                                <div className="d-flex flex-row gap-3 mt-4">
                                                    <div className="d-flex align-items-center">
                                                        <label htmlFor="coOwnerPicture" className="d-flex align-items-center" style={{cursor: 'pointer'}}>
                                                            <i className="material-symbols-outlined">attach_file</i>
                                                        </label>
                                                        <input
                                                            id="coOwnerPicture"
                                                            name="coOwnerPicture"
                                                            type="file"
                                                            accept="image/*"
                                                            className="d-none"
                                                            // onChange={(e) => handleAttachPicture(e)}
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="border-0 bg-transparent d-flex align-items-center text-danger"
                                                        // onClick={() => setAttachedPicture(null)}
                                                    >
                                                        <i className="material-symbols-outlined">delete</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section id="roles">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="rolesHeading">
                                    Roles
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col lg={6} className='d-flex flex-row gap-4'>
                                            <div className="form-check col-sm px-5 py-3 border border-light d-flex justify-content-center align-items-center rounded-3">
                                                <input 
                                                    className="border border-primary" 
                                                    type="checkbox" 
                                                    name="role" 
                                                    value="ADMIN"
                                                    id="role" 
                                                />
                                                <label className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center" htmlFor="role">
                                                    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_2342_8352)">
                                                        <path d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z" fill="#6C757D"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_2342_8352">
                                                        <rect width="28" height="30" fill="white" transform="translate(0.208984)"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>Admin</span>
                                                </label>
                                            </div>

                                            <div className="form-check col-sm px-5 py-3 border border-light d-flex justify-content-center align-items-center rounded-3">
                                                <input 
                                                    className="border border-primary" 
                                                    type="checkbox" 
                                                    name="role" 
                                                    value="BOARD"
                                                    id="role" 
                                                />
                                                <label className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center" htmlFor="role">
                                                    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_2342_8352)">
                                                        <path d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z" fill="#6C757D"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_2342_8352">
                                                        <rect width="28" height="30" fill="white" transform="translate(0.208984)"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>Board</span>
                                                </label>
                                            </div>

                                            <div className="form-check col-sm px-5 py-3 border border-light d-flex justify-content-center align-items-center rounded-3">
                                                <input 
                                                    className="border border-primary" 
                                                    type="checkbox" 
                                                    name="role" 
                                                    value="SUB"
                                                    id="role" 
                                                />
                                                <label className="form-check-label ms-2 d-flex flex-column gap-2 align-items-center" htmlFor="role">
                                                    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_2342_8352)">
                                                        <path d="M6.20898 7.5C6.20898 9.48912 7.05184 11.3968 8.55213 12.8033C10.0524 14.2098 12.0873 15 14.209 15C16.3307 15 18.3655 14.2098 19.8658 12.8033C21.3661 11.3968 22.209 9.48912 22.209 7.5C22.209 5.51088 21.3661 3.60322 19.8658 2.1967C18.3655 0.790176 16.3307 0 14.209 0C12.0873 0 10.0524 0.790176 8.55213 2.1967C7.05184 3.60322 6.20898 5.51088 6.20898 7.5ZM12.1152 19.2305L13.2777 21.0469L11.1965 28.3066L8.94648 19.6992C8.82148 19.2246 8.33398 18.9141 7.82773 19.0371C3.45273 20.0625 0.208984 23.7773 0.208984 28.2012C0.208984 29.1973 1.07148 30 2.12773 30H10.359C10.359 30 10.359 30 10.3652 30H10.709H17.709H18.0527C18.0527 30 18.0527 30 18.059 30H26.2902C27.3527 30 28.209 29.1914 28.209 28.2012C28.209 23.7773 24.9652 20.0625 20.5902 19.0371C20.084 18.9199 19.5965 19.2305 19.4715 19.6992L17.2215 28.3066L15.1402 21.0469L16.3027 19.2305C16.7027 18.6035 16.2215 17.8125 15.4465 17.8125H14.209H12.9777C12.2027 17.8125 11.7215 18.6094 12.1215 19.2305H12.1152Z" fill="#6C757D"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_2342_8352">
                                                        <rect width="28" height="30" fill="white" transform="translate(0.208984)"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>Sub</span>
                                                </label>
                                            </div>
                                        </Col>
                                        <Col lg={6} className='d-flex flex-row gap-4 align-items-center'>
                                            <label  className="control-label mb-0 col-sm-3">Sub-committee</label>
                                            <select
                                                className="form-select col-sm"
                                                id="subCommittee"
                                                name='subCommittee'
                                            >
                                                <option defaultValue="null">-</option>
                                                <option value="ARC">ARC</option>
                                                <option value="Events">EVENTS</option>
                                            </select> 
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section id="households">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="householdsHeading">
                                    Households
                                </Accordion.Header>
                                <Accordion.Body className='d-flex flex-row align-items-center gap-4'>
                                    <div className="d-flex mb-auto mt-1">
                                        <button
                                            type="button"
                                            className="bg-primary py-1 d-flex align-items-center border-0 text-white rounded-3"
                                            onClick={() => handleAddArrayFn('households')}
                                        >
                                            <i className="material-symbols-outlined">add</i>
                                        </button>
                                    </div>
                                    <div className='d-flex flex-column gap-4 align-items-center w-100'>
                                        <div className='d-flex flex-row gap-4 align-items-center w-100'>
                                            <div className='w-100'>
                                                <input 
                                                    type="text"
                                                    className="form-control w-100" 
                                                    id="houseHoldName" 
                                                    name="houseHoldName" 
                                                    value={newHousehold.name}
                                                    required
                                                    placeholder="Name.." 
                                                    onChange={(e) => setNewHousehold({
                                                        ...newHousehold,
                                                        name: e.target.value
                                                    })} 
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <input 
                                                    type="text" 
                                                    className="form-control w-100" 
                                                    id="houseHoldPhone" 
                                                    name="houseHoldPhone" 
                                                    value={newHousehold.phone}
                                                    placeholder="Phone.." 
                                                    onChange={(e) => setNewHousehold({
                                                        ...newHousehold,
                                                        phone: e.target.value
                                                    })} 
                                                />
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <button
                                                    type="button"
                                                    className="bg-transparent py-1 d-flex align-items-center border-0 text-danger rounded-3"
                                                >
                                                    <div style={{width: '24px', height: '24px'}}></div>
                                                </button>
                                            </div>
                                        </div>
                                        {houseHolds?.map((houseHoldItem, idx) => (
                                            !isStateUpdated ? (
                                                <div key={idx} className='d-flex flex-row gap-4 align-items-center w-100'>
                                                    <div className='w-100'>
                                                        <input 
                                                            type="text"
                                                            className="form-control w-100" 
                                                            id={`houseHoldName-${idx}`} 
                                                            name={`houseHoldName-${idx}`} 
                                                            required
                                                            placeholder="Name.." 
                                                            readOnly={true}
                                                            defaultValue={houseHoldItem?.name}
                                                        />
                                                    </div>
                                                    <div className='w-100'>
                                                        <input 
                                                            type="text" 
                                                            className="form-control w-100" 
                                                            id={`houseHoldPhone-${idx}`} 
                                                            name={`houseHoldPhone-${idx}`} 
                                                            placeholder="Phone.." 
                                                            readOnly={true}
                                                            defaultValue={houseHoldItem?.phone}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <button
                                                            type="button"
                                                            className="bg-transparent py-1 d-flex align-items-center border-0 text-danger rounded-3"
                                                            onClick={() => handleRemoveArrayFn({index: idx, type: 'households'})}
                                                        >
                                                            <i className="material-symbols-outlined">delete</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : <SpinningBlueCircleLoader />
                                        ))}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section id="cars">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="carsHeading">
                                    Cars
                                </Accordion.Header>
                                <Accordion.Body className='d-flex flex-column gap-4 align-items-center'>
                                    <div className='d-flex flex-row align-items-center gap-4'>
                                        <div className="d-flex mb-auto mt-1">
                                            <button
                                                type="button"
                                                className="bg-primary py-1 d-flex align-items-center border-0 text-white rounded-3"
                                                onClick={() => handleAddArrayFn("cars")}
                                            >
                                                <i className="material-symbols-outlined">add</i>
                                            </button>
                                        </div>
                                        <div className='d-flex flex-column gap-4 align-items-center w-100'>
                                            <div className='d-flex flex-row gap-4 align-items-center w-100'>
                                                <div className='w-100'>
                                                    <input type="text" value={newCar?.make} className="form-control w-100" id="carMake" name="carMake" placeholder="Make.." required 
                                                        onChange={(e) => setNewCar({
                                                            ...newCar,
                                                            make: e.target.value
                                                        })} 
                                                    />
                                                </div>
                                                <div className='w-100'>
                                                    <input type="text" value={newCar?.model} className="form-control w-100" id="carModel" name="carModel" placeholder="Model.." required 
                                                        onChange={(e) => setNewCar({
                                                            ...newCar,
                                                            model: e.target.value
                                                        })} 
                                                    />
                                                </div>
                                                <div className='w-100'>
                                                    <input type="text" value={newCar?.year} className="form-control w-100" id="carYear" name="carYear" placeholder="Year.." required
                                                        onChange={(e) => setNewCar({
                                                            ...newCar,
                                                            year: e.target.value
                                                        })} 
                                                    />
                                                </div>
                                                <div className='w-100'>
                                                    <input type="text" value={newCar?.color} className="form-control w-100" id="carColor" name="carColor" placeholder="Color.." required
                                                        onChange={(e) => setNewCar({
                                                            ...newCar,
                                                            color: e.target.value
                                                        })} 
                                                    />
                                                </div>
                                                <div className='w-100'>
                                                    <input type="text" value={newCar?.plateNumber} className="form-control w-100" id="carPlate" name="carPlate" placeholder="Plate.." required
                                                        onChange={(e) => setNewCar({
                                                            ...newCar,
                                                            plateNumber: e.target.value
                                                        })} 
                                                    />
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <button
                                                        type="button"
                                                        className="bg-transparent py-1 d-flex align-items-center border-0 text-danger rounded-3"
                                                    >
                                                         <div style={{width: '24px', height: '24px'}}></div>
                                                    </button>
                                                </div>
                                            </div>
                                            {cars?.map((carItem, idx) => (
                                                !isStateUpdated ? (
                                                    <div key={idx} className='d-flex flex-row gap-4 align-items-center w-100'>
                                                        <div className='w-100'>
                                                            <input type="text" defaultValue={carItem?.make} className="form-control w-100" id={`carMake-${idx}`} name={`carMake-${idx}`} placeholder="Make.." required 
                                                                onChange={(e) => setNewCar({
                                                                    ...newCar,
                                                                    make: e.target.value
                                                                })} 
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='w-100'>
                                                            <input type="text" defaultValue={carItem?.model} className="form-control w-100" id={`carModel-${idx}`} name={`carModel-${idx}`} placeholder="Model.." required 
                                                                onChange={(e) => setNewCar({
                                                                    ...newCar,
                                                                    model: e.target.value
                                                                })} 
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='w-100'>
                                                            <input type="text" defaultValue={carItem?.year} className="form-control w-100" id={`carYear-${idx}`} name={`carYear-${idx}`} placeholder="Year.." required
                                                                onChange={(e) => setNewCar({
                                                                    ...newCar,
                                                                    year: e.target.value
                                                                })} 
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='w-100'>
                                                            <input type="text" defaultValue={carItem?.color} className="form-control w-100" id={`carColor-${idx}`} name={`carColor-${idx}`} placeholder="Color.." required 
                                                                onChange={(e) => setNewCar({
                                                                    ...newCar,
                                                                    color: e.target.value
                                                                })} 
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='w-100'>
                                                            <input type="text" defaultValue={carItem?.plateNumber} className="form-control w-100" id={`carPlate-${idx}`} name={`carPlate-${idx}`} placeholder="Plate.." required
                                                                onChange={(e) => setNewCar({
                                                                    ...newCar,
                                                                    plateNumber: e.target.value
                                                                })} 
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <button
                                                                type="button"
                                                                className="bg-transparent py-1 d-flex align-items-center border-0 text-danger rounded-3"
                                                                onClick={() => handleRemoveArrayFn({index: idx, type: 'cars'})}
                                                            >
                                                                <i className="material-symbols-outlined">delete</i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : <SpinningBlueCircleLoader />
                                            ))}
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section id="listing-notifications">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="listingsNotificationsHeading">
                                    Listings and Notifications
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col lg={6} className='d-flex flex-column gap-2'>
                                            <div className='d-flex flex-row gap-4 align-items-center'>
                                                <div>Phone Unlisted</div>
                                                <Form.Check 
                                                    className="d-flex align-items-center form-switch form-check-inline"
                                                    style={{height: '50px'}}
                                                >
                                                    <Form.Check.Input
                                                        type="checkbox"
                                                        className="bg-primary"
                                                        id="telephone"
                                                        name="telephone"
                                                    />
                                                </Form.Check>
                                            </div>
                                            <div className='d-flex flex-row gap-4 align-items-center'>
                                                <div>Profile</div>
                                                <Form.Check 
                                                    className="d-flex align-items-center form-switch form-check-inline"
                                                    style={{height: '50px'}}
                                                >
                                                    <Form.Check.Input
                                                        type="checkbox"
                                                        className="bg-primary"
                                                        id="profile"
                                                        name="profile"
                                                    />
                                                </Form.Check>
                                            </div>
                                        </Col>
                                        <Col lg={6} className='d-flex flex-column gap-2'>
                                            <div className='d-flex flex-row gap-4 align-items-center'>
                                                <div>Two Factor Security</div>
                                                <Form.Check 
                                                    className="d-flex align-items-center form-switch form-check-inline"
                                                    style={{height: '50px'}}
                                                >
                                                    <Form.Check.Input
                                                        type="checkbox"
                                                        className="bg-primary"
                                                        id="twoFa"
                                                        name="twoFa"
                                                    />
                                                </Form.Check>
                                            </div>
                                            <div className='d-flex flex-row gap-4 align-items-center'>
                                                <div>SMS</div>
                                                <Form.Check 
                                                    className="d-flex align-items-center form-switch form-check-inline"
                                                    style={{height: '50px'}}
                                                >
                                                    <Form.Check.Input
                                                        type="checkbox"
                                                        className="bg-primary"
                                                        id="sms"
                                                        name="sms"
                                                    />
                                                </Form.Check>
                                            </div>
                                            <div className='d-flex flex-row gap-4 align-items-center'>
                                                <div>Email</div>
                                                <Form.Check 
                                                    className="d-flex align-items-center form-switch form-check-inline"
                                                    style={{height: '50px'}}
                                                >
                                                    <Form.Check.Input
                                                        type="checkbox"
                                                        className="bg-primary"
                                                        id="email"
                                                        name="email"
                                                    />
                                                </Form.Check>
                                            </div>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                        <section id="billing">
                            <Accordion.Item className="mb-3 bg-primary" eventKey="0">
                                <Accordion.Header id="billingHeading">
                                    Billing
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className='align-items-center justify-content-center'>
                                        <Col lg={6} className='d-flex flex-column gap-2'>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <input 
                                                        className="border border-primary" 
                                                        type="checkbox" 
                                                        name="isRental" 
                                                        id="isRental" 
                                                    />
                                                    <label htmlFor="isRental" className='col-sm-4'>
                                                        Is the house rental
                                                    </label>
                                                    <input type="text" className="col-sm border border-light px-3 py-2 rounded-2 w-100" id="rentalFee" name="rentalFee" placeholder="Rental Fee" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <label htmlFor="generateEvery" className='col-sm-4'>
                                                        Generate billing every
                                                    </label>
                                                    <input type="number" className="col-sm border border-light px-3 py-2 rounded-2" id="generateEvery" name="generateEvery" placeholder="1st" required />
                                                    <span> of the month</span>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <label htmlFor="showInvoiceDate" className='col-sm-4'>
                                                        Show invoice date
                                                    </label>
                                                    <input type="number" className="col-sm border border-light px-3 py-2 rounded-2" id="showInvoiceDate" name="showInvoiceDate" placeholder="5th" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <label htmlFor="dueDate" className='col-sm-4'>
                                                        Due date
                                                    </label>
                                                    <input type="text" className="col-sm border border-light px-3 py-2 rounded-2" id="dueDate" name="dueDate" placeholder="15th" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <label htmlFor="monthlyFee" className='col-sm-4'>
                                                        Monthly fee
                                                    </label>
                                                    <input type="text" className="col-sm border border-light px-3 py-2 rounded-2" id="monthlyFee" name="monthlyFee" placeholder="300.." required />
                                                </div>
                                            </Row>
                                        </Col>
                                        <Col lg={6} className='d-flex flex-column gap-2'>  
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <span className='col-sm-4'>
                                                        Billing Email Recipient
                                                    </span>
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <label htmlFor="primaryEmail" className='col-sm-4'>
                                                        Primary
                                                    </label>
                                                    <input type="text" className="col-sm border border-light px-3 py-2 rounded-2" id="primaryEmail" name="primaryEmail" placeholder="-" required />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <label htmlFor="emailCC" className='col-sm-4'>
                                                        CC
                                                    </label>
                                                    <input type="text" className="col-sm border border-light px-3 py-2 rounded-2" id="emailCC" name="emailCC" placeholder="-" />
                                                </div>
                                            </Row>
                                            <Row>
                                                <div className='d-flex flex-row gap-2 align-items-center'>
                                                    <label htmlFor="paymentMethod" className='col-sm-4'>
                                                        Payment Method
                                                    </label>
                                                    <select
                                                        className="form-select col-sm"
                                                        id="paymentMethod"
                                                        name='paymentMethod'
                                                        required
                                                    >
                                                        <option defaultValue="-">-</option>
                                                        <option value="ACH">ACH</option>
                                                    </select> 
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </section>
                    </Accordion>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type='submit' className='w-100 mx-4'>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        )
    }
    
    return (
        <Modal size="xl" show={show} onHide={() => setShow(false)} style={{ display: 'block' }}>
            {renderModalForm()}
        </Modal>
    )
};

export default CreateUpdateHomeownerModal;